* {
  box-sizing: border-box;
  background-color: #0e1212;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  max-width: 800px;
  margin: auto
}

header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 0px;
  margin: 0px;
}

header h1 {
  font-size: 35px;
  color: #682ae9;
  margin-bottom: 1px;
}

header h2 {
  font-size: 25px;
  color: #dbdbdb;
  letter-spacing: 5px;
  margin: 0px;
}

section {
  display: -webkit-flex;
  display: flex;
}


nav {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

article {
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  padding: 10px;
  color: #808080;
}

article a {
  text-decoration: none;
  color: #682ae9;
}

article a:hover {
  color: #dbdbdb
}

article img {
  padding: 5px;
}

footer {
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin: auto;
}

footer a {
  text-decoration: none;
  color: #682ae9;
}

footer a:hover {
  color: #dbdbdb;
}

img {
  max-width: 20%;
  float: right;
}

@media (max-width: 600px) {
  section {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.icon {
  margin-left: 5px;
  margin-right: 5px;
}